<template>
  <div class="w3-white">
    <div v-if="1 === 2" class="w3-right-align mb-2">
      <button
        @click.prevent="PrintStatement('investments')"
        class="w3-button w3-white w3-border py-2 width150"
        :disabled="fetchingStatement"
      >
        <span v-html="fetchingStatement ? spinner : 'Print Statement'"></span>
      </button>
    </div>
    <div class="row mb-2">
      <div class="col-sm-5">
        <h4 class="bold">Transactions</h4>
      </div>
      <div class="col-sm-7">
        <div class="w3-right">
          <DateRangeFilter @gettransactions="getTransactions" />
        </div>
      </div>
    </div>
    <div v-if="fetching">
      <img class="mr-3" src="base/img/loading.gif" width="40" />
      Loading transactions
    </div>
    <div v-else>
      <TransactionsTable
        tableid="TransactionsTable"
        :headers="TableHeader"
        :rows="Transactions"
        source="mi"
      />
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, onBeforeMount } from "vue";
import TransactionsTable from "@/components/TransactionsTable";
import DateRangeFilter from "@/components/DateRangeFilter";
import usePrintStatement from "@/composables/use-print-statement";
export default {
  components: { TransactionsTable, DateRangeFilter },
  setup() {
    const store = useStore();
    const Transactions = computed(() => store.state.MITransactions);
    const fetching = computed(() => store.state.fetching.MITransactions);
    const ContactNo = computed(() => store.state.userData.contactNo);
    const SETTINGS = computed(() => store.state.appSettings);

    const TableHeader = ref([
      { text: "Value Date", value: "ValueDate" },
      { text: "Amount Invested", value: "AmountInvested" },
      { text: "Description", value: "Description" },
      { text: "Value At Maturity", value: "ExpectedValueAtMaturity" },
      { text: "Accrued", value: "MarginAtToday" },
      { text: "Maturity Date", value: "MaturityDate" },
      { text: "Tenor", value: "Tenor" },
    ]);

    const StartDate = ref("");
    const EndDate = ref("");
    const getTransactions = (period) => {
      store.dispatch("MITransactionHistory", {
        StartDate: period.StartDate,
        EndDate: period.EndDate,
      });
    };
    if (!Transactions.value?.length) {
      store.dispatch("MITransactionHistory", {
        StartDate: StartDate.value,
        EndDate: EndDate.value,
      });
    }

    const { getStatement, fetchingStatement, spinner } = usePrintStatement();
    const PrintStatement = (type) =>
      getStatement(
        type,
        ContactNo.value,
        SETTINGS.value._MURABABA_PLAN_CODE,
        SETTINGS.value._MURABABA_PLAN_TITLE
      );

    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      fetching,
      TableHeader,
      Transactions,
      getTransactions,
      StartDate,
      EndDate,
      spinner,
      PrintStatement,
      fetchingStatement,
    };
  },
};
</script>