<template>
  <teleport to="#modalTeleports">
    <div
      :class="[loadInvestmentIframe ? 'w3-block' : '']"
      class="w3-modal"
      style="padding: 2vh"
    >
      <div
        class="w3-modal-content w3-animate-top w3-center bg-transparent"
        style="width: 95vw"
      >
        <button
          class="w3-button w3-red w3-round w3-round-xxlarge absolute"
          style="top: 10px; right: 10px"
          @click.prevent="closeInvestmentIframe('MIInvestmentPaymentbox')"
        >
          x
        </button>

        <iframe
          name="MIInvestmentPaymentbox"
          id="MIInvestmentPaymentbox"
          style="width: 100%; height: 95vh; border: 0px solid #fff"
        ></iframe>
      </div>
    </div>
  </teleport>
  <img
    class="m-3 fetching"
    v-if="!Products.length"
    src="base/img/loading.gif"
    width="80"
  />
  <form
    @submit.prevent="Payment"
    id="MIInvestmentPaymentForm"
    method="POST"
    style="max-width: 840px"
  >
    <div
      v-show="activeStep === 'InvestmentAmount'"
      data-step="InvestmentAmount"
      class="steps"
    >
      <div class="row">
        <div class="col-md-6 pb-3">
          <h3 class="mt-0">How much would you like to invest?</h3>
          <div v-html="'E.g. 50,000'" class="size14"></div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label size13">Amount to invest</label>
            <div class="input-group max-width250">
              <span v-html="SETTINGS._CURRENCY" class="input-group-text"></span>
              <input
                name="InvestmentAmount"
                v-model="investment.InvestmentAmount"
                @change="getRate"
                class="form-control form-control-lg currency"
              />
            </div>
          </div>

          <div class="actions">
            <button
              type="button"
              class="nextStep w3-button w3-red w3-hover-orange"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="activeStep === 'InvestmentTenor'"
      data-step="InvestmentTenor"
      class="steps"
    >
      <div class="row">
        <div class="col-md-6 pb-3">
          <h3 class="mt-0">What's the tenor for the investment?</h3>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label size13">Tenor of investment</label>
            <select
              v-model="investment.InvestmentTenor"
              name="InvestmentTenor"
              @change="getRate"
              class="form-control form-control-lg"
            >
              <option value=""></option>
              <option v-for="(tenor, key) in MI_TENOR" :key="key" :value="key">
                {{ key }} days
              </option>
            </select>
            <table class="table w3-bordered w3-border mt-0">
              <tr>
                <td>Amount</td>
                <td>&#8358;{{ investment.InvestmentAmount }}</td>
              </tr>
              <tr>
                <td>Tenor</td>
                <td>{{ investment.InvestmentTenor ?? "0" }} days</td>
              </tr>
              <tr>
                <td>Rate</td>
                <td>{{ investment.InvestmentRate ?? "-" }}</td>
              </tr>
            </table>
          </div>

          <div class="actions">
            <button
              type="button"
              class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
            >
              Back</button
            ><button
              type="button"
              class="nextStep w3-button w3-red w3-hover-orange"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="activeStep === 'Payment'" class="steps" data-step="Payment">
      <div class="row equalHeightColumns">
        <div class="col-md-6 column w3-theme-d5">
          <div class="m-1 px-4 py-4 w3-round-large">
            <label class="control-label w3-text-orange">
              Investment Amount</label
            >
            <h3 class="mt-0 mb-3 bold">
              {{ formattedCurrency(InvestmentAmount, "&#8358;") }}
            </h3>
            <label class="control-label w3-text-orange"> Fund Name</label>
            <h5 class="mt-0">
              {{ investment.InvestmentName }}
            </h5>
            <label class="control-label w3-text-orange"> Fund Code</label>
            <h5 class="mt-0">
              {{ investment.InvestmentType }}
            </h5>
            <label class="control-label w3-text-orange">
              Investment Tenor</label
            >
            <h5 class="mt-0">{{ investment.InvestmentTenor ?? "-" }} days</h5>
            <label class="control-label w3-text-orange"> Investment Rate</label>
            <h5 class="mt-0">{{ investment.InvestmentRate ?? "-" }}</h5>
          </div>
        </div>
        <div class="col-md-6 column">
          <div class="m-1 px-4 py-4">
            <h3 class="mt-0 mb-4">Payment</h3>

            <p>
              You will be prompted to choose a payment method and provide your
              billing information.
            </p>
            <p>Please click the button below to proceed</p>

            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button type="submit" class="w3-button w3-red w3-hover-orange">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" :value="uuid()" name="uuid" />
    <input type="hidden" name="TransRef" v-model="investment.TransRef" />
    <input type="hidden" name="ContactNo" v-model="ContactNo" />
    <input type="hidden" name="Email" v-model="Email" />
    <input type="hidden" name="PhoneNo" v-model="PhoneNo" />

    <input type="hidden" name="Fullname" v-model="Fullname" />
    <input
      type="hidden"
      name="InvestmentUnits"
      v-model="investment.InvestmentTenor"
    />
    <input
      type="hidden"
      name="InvestmentRate"
      v-model="investment.InvestmentRate"
    />

    <input type="hidden" name="callback" :value="callback" />

    <input
      type="hidden"
      name="PaymentTitle"
      :value="'Payment for ' + investment.InvestmentName + ' investment'"
    />
    <input
      type="hidden"
      name="InvestmentName"
      v-model="investment.InvestmentName"
    />
    <input
      type="hidden"
      name="InvestmentType"
      v-model="investment.InvestmentType"
    />
    <input
      type="hidden"
      name="InvestmentOffer"
      v-model="investment.InvestmentOffer"
    />
  </form>
</template>
<script>
import formatCurrency from "format-currency";
import "@dmuy/jquery-datepicker/duDatepicker.css";
import duDatepicker from "@dmuy/jquery-datepicker";
import { formattedCurrency, backendURL } from "@/helpers/utilities";
import { v4 as uuid } from "uuid";
import $ from "jquery";
import { ref, computed, toRef, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import usePayments from "@/composables/use-payments";

export default {
  components: {},
  props: ["callback", "fullname", "email", "contactno", "phoneno"],
  emits: ["finished"],
  setup(props, { emit }) {
    const store = useStore();
    const title = ref("Add a new investment");
    const SETTINGS = computed(() => store.state.appSettings);
    const Callback = toRef(props, "callback");
    const Fullname = toRef(props, "fullname");
    const Email = toRef(props, "email");
    const ContactNo = toRef(props, "contactno");
    const PhoneNo = toRef(props, "phoneno");

    const MIFundCode = SETTINGS.value._MURABABA_PLAN_CODE;

    const activeStep = ref("InvestmentAmount");

    const FundProducts = computed(() => store.state.Products);
    const Products = computed(() => {
      const MI = [];
      const INV = [];
      for (let key in FundProducts.value) {
        if (FundProducts.value[key].FundCode === MIFundCode) {
          MI.push(FundProducts.value[key]);
        } else {
          INV.push(FundProducts.value[key]);
        }
      }
      return Callback.value === "mi" ? MI : INV;
    });
    const MIProduct = ref(Products.value[0]);
    const MI_TENOR = ref(SETTINGS.value._MI_TENOR);
    const MI_PRICE_RANGE = ref(SETTINGS.value._MI_PRICE_RANGE);

    const getRate = () => {
      const Amount = InvestmentAmount.value;
      let Range = "PR1";
      for (let key in MI_PRICE_RANGE.value) {
        var Value = MI_PRICE_RANGE.value[key].replace(/,/g, "");
        if (eval(Amount + " " + Value)) {
          Range = key;
          break;
        }
      }
      if (investment.value.InvestmentTenor) {
        investment.value.InvestmentRate =
          MI_TENOR.value[investment.value.InvestmentTenor][Range];
      } else {
        investment.value.InvestmentRate = "-";
        investment.value.InvestmentTenor = "0";
      }
    };
    const InvestmentAmount = computed(() =>
      investment.value.InvestmentAmount
        ? investment.value.InvestmentAmount.replace(/,/g, "")
        : 0
    );
    const investment = ref({
      TransRef: "",
      InvestmentName: "",
      InvestmentType: "",
      InvestmentOffer: "",
      InvestmentUnits: 0,
      InvestmentAmount: 0,
      TempContactNo: ContactNo.value,
    });
    const SelectInvestment = (inv, offer, InvestmentName) => {
      investment.value.InvestmentType = inv;
      investment.value.InvestmentOffer = offer;
      investment.value.InvestmentName = InvestmentName;
    };
    const retry = ref(1);
    const PaymentSuccess = (data) => {
      investment.value.TransRef = data.Trx.PaymentRef;
      if (data.Trx.apiCalled === 1) {
        store.dispatch("MIRunningBal");
        console.log("emit finished");
        emit("finished");
      } else {
        if (retry.value <= 3) {
          retry.value++;
          InvestmentIframeCallback(investment.value.TransRef, retry.value);
        }
      }
    };
    const {
      loadIframe: loadInvestmentIframe,
      closeIframe: closeInvestmentIframe,
      IframeCallback: InvestmentIframeCallback,
    } = usePayments(PaymentSuccess);

    const Payment = () => {
      if (!(InvestmentAmount.value > 0)) {
        store.dispatch("showAlert", {
          message: "Please enter an amount to invest",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (InvestmentAmount.value < SETTINGS.value._MIN_AMOUNT) {
        store.dispatch("showAlert", {
          message:
            "Amount to invest cannot be less than " +
            SETTINGS.value._MIN_AMOUNT,
          status: "error",
          showing: true,
        });
        return false;
      }

      if (!investment.value.InvestmentTenor) {
        store.dispatch("showAlert", {
          message: "Please select an Investment tenor",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (investment.value.TransRef) {
        InvestmentIframeCallback(investment.value.TransRef);
        return;
      }
      $("form#MIInvestmentPaymentForm")
        .attr("action", backendURL + "/payment/globalpay/invest")
        .submit();
      loadInvestmentIframe.value = true;
    };

    onBeforeMount(() => {
      SelectInvestment(
        MIProduct.value.FundCode,
        MIProduct.value.Offer,
        MIProduct.value.InvestmentName
      );

      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      $(".prevStep").on("click", function () {
        const parent = $(this).parents(".steps:first");
        const prev = parent.prev(".steps");
        if (prev.length) {
          $("div.steps").hide();
          prev.fadeIn();
        }
      });
      $(".nextStep").on("click", function () {
        const parent = $(this).parents(".steps:first");
        const next = parent.next(".steps");
        const step = parent.data("step");

        switch (step) {
          case "InvestmentTenor":
            if (investment.value.InvestmentTenor === "0") {
              store.dispatch("showAlert", {
                message: "Please select an Investment tenor",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;
          case "InvestmentAmount":
            if (!(InvestmentAmount.value > 0)) {
              store.dispatch("showAlert", {
                message: "Please enter an amount to invest",
                status: "error",
                showing: true,
              });
              return false;
            }
            if (InvestmentAmount.value < SETTINGS.value._MIN_AMOUNT) {
              store.dispatch("showAlert", {
                message:
                  "Amount to invest cannot be less than " +
                  SETTINGS.value._MIN_AMOUNT,
                status: "error",
                showing: true,
              });
              return false;
            }
            break;
        }
        if (next.length) {
          $("div.steps").hide();
          next.fadeIn();
        }
      });

      $(".currency").on("each", function () {
        investment.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("blur", function () {
        investment.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("focus", function () {
        investment.value[$(this).attr("name")] = $(this)
          .val()
          .replace(/,/g, "");
      });
    });
    return {
      title,
      investment,
      Fullname,
      Email,
      PhoneNo,
      ContactNo,
      SETTINGS,
      activeStep,
      duDatepicker,
      Products,
      SelectInvestment,
      InvestmentAmount,
      Payment,
      formattedCurrency,
      uuid,
      MI_TENOR,
      getRate,
      loadInvestmentIframe,
      closeInvestmentIframe,
    };
  },
};
</script>
<style scoped>
.steps {
  padding: 50px 40px;
  background-color: #fff;
}
.actions {
  margin-top: 40px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
</style>

