<template>
  <div class="col-xl-4 col-md-6">
    <section class="investmentSummaryBox column card shadow w3-border mb-4">
      <div class="investmentSummary card-body m-0 py-4 px-4">
        <div class="center-vertical">
          <i class="bx bx-pie-chart-alt-2 size60 w3-left w3-text-orange"></i>
          <div class="ml-2">
            <div v-if="length" class="mb-2">
              <span class="w3-text-white">{{ length }} PACKAGES</span>
            </div>
            <div v-if="amount" class="mt-0 mb-2 size15 bold w3-text-white">
              <p class="my-0 size11 w3-text-orange">TOTAL AMOUNT INVESTED</p>
              <p class="mt-0 mb-2 size14 bold w3-text-white">
                {{
                  amount.totalInvested
                    ? formatCurrency(amount.totalInvested, "&#8358;")
                    : "&#8358;0.00"
                }}
              </p>
              <p class="my-0 size11 w3-text-orange">TOTAL VALUE TODAY</p>
              <p class="mt-0 mb-2 size14 bold w3-text-white">
                {{
                  amount.totalValueToday
                    ? formatCurrency(amount.totalValueToday, "&#8358;")
                    : "&#8358;0.00"
                }}
              </p>
              <p class="my-0 size11 w3-text-orange">TOTAL VALUE AT MATURITY</p>
              <p class="mt-0 mb-0 size14 bold w3-text-white">
                {{
                  amount.totalExpected
                    ? formatCurrency(amount.totalExpected, "&#8358;")
                    : "&#8358;0.00"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { formatCurrency } from "@/helpers/utilities";
import { onMounted } from "@vue/runtime-core";
export default {
  props: ["amount", "length"],
  setup(_, { emit }) {
    onMounted(() => {
      emit("mounted");
    });
    return {
      formatCurrency,
    };
  },
};
</script>
<style scoped>
.investmentSummaryBox {
  max-width: 100%;
}
.investmentSummary {
  background-color: #8d1d1c;
  background-image: url("/base/img/bg-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.investmentSummary p {
  line-height: normal !important;
}
</style>