<template>
  <div class="p-0">
    <div v-if="Investment" class="p-0">
      <div class="pt-5 pr-5 pb-3 pl-5 w3-theme-d5">
        <div class="w3-row">
          <div class="w3-col m8 mb-3">
            <p class="bold size24 my-0">
              Mudarabah Plan ({{ Investment.FundCode }})
            </p>
            <div class="w3-text-orange m-0 size11">Fund Name</div>
          </div>
          <div class="w3-col m4 mb-3">
            <p class="bold size24 my-0">({{ Investment.PackageId }})</p>
            <div class="w3-text-orange m-0 size11">Package ID</div>
          </div>
        </div>

        <div class="w3-row">
          <div class="w3-col m4 mb-3">
            <p class="bold size16 my-0">
              {{ formattedCurrency(Investment.AmountInvested, "&#8358;") }}
            </p>
            <div class="w3-text-orange m-0 size11">Amount Invested</div>
          </div>
          <div class="w3-col m4 mb-3">
            <p class="size16 my-0">
              {{
                formattedCurrency(Investment.InvestmentValueAtToday, "&#8358;")
              }}
            </p>
            <div class="w3-text-orange m-0 size11">
              Investment Value At Today
            </div>
          </div>
          <div class="w3-col m4 mb-3">
            <p class="size16 my-0">
              {{
                formattedCurrency(Investment.ExpectedValueAtMaturity, "&#8358;")
              }}
            </p>
            <div class="w3-text-orange m-0 size11">
              Expected Value At Maturity
            </div>
          </div>
        </div>
        <div class="w3-row">
          <div class="w3-col m4 mb-3">
            <p class="size16 my-0">{{ Investment.Tenor }} days</p>
            <div class="w3-text-orange m-0 size11">Tenor</div>
          </div>

          <div class="w3-col m4 mb-3">
            <p class="size16 my-0">
              {{ Investment.BookingDate.substring(2, 10) }}
            </p>
            <div class="w3-text-orange m-0">Booking Date</div>
          </div>
          <div class="w3-col m4 mb-3">
            <p class="size16 my-0">
              {{ Investment.MaturityDate.substring(2, 10) }}
            </p>
            <div class="w3-text-orange m-0">Maturity Date</div>
          </div>
        </div>
        <InvestmentsTopup
          :Investmentyype="Investment.FundCode"
          :referral="referral"
          :contactno="contactno"
          :email="email"
          :phoneno="phoneno"
          :fullname="fullname"
        />
        <InvestmentsRedemption
          :referral="referral"
          :contactno="contactno"
          :email="email"
          :fullname="fullname"
          :phoneno="phoneno"
          :Investmentyype="Investment.FundCode"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { toRef, computed, onBeforeMount } from "vue";
import { formattedCurrency } from "@/helpers/utilities";
import InvestmentsTopup from "@/components/InvestmentsTopup";
import InvestmentsRedemption from "@/components/InvestmentsRedemption";
import { useStore } from "vuex";
export default {
  props: [
    "investment",
    "referral",
    "contactno",
    "email",
    "fullname",
    "phoneno",
  ],
  components: {
    InvestmentsTopup,
    InvestmentsRedemption,
  },
  setup(props) {
    const store = useStore();
    const Investment = toRef(props, "investment");
    const SETTINGS = computed(() => store.state.appSettings);
    Investment.value.FundCode = SETTINGS.value._MURABABA_PLAN_CODE;
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      Investment,
      formattedCurrency,
    };
  },
};
</script>
<style scoped>
.box {
  padding: 20px 40px;
}
.no-wrap {
  white-space: nowrap;
}
.w3-theme-d5 p {
  color: #fff;
}
</style>