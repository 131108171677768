import { ref } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

export default function PrintStatement() {
  const store = useStore()
  const fetchingStatement = ref(false)
  const spinner = ref('<i class="bx bx-loader bx-spin" ></i>')


  const getStatement = (type, contactNo, fundType, fundName) => {
    fetchingStatement.value = true
    axios({
      method: 'get',
      url: '/api/Reports/Statements/' + type,
      params: { UserId: contactNo, fundType: fundType, fundName: fundName }
    })
      .then((response) => {
        fetchingStatement.value = false
        if (response.data.status === 'success') {
          store.dispatch('showAlert', {
            message: 'Statement sent successfully',
            status: status,
            showing: true
          })
        } else {
          store.dispatch('showAlert', {
            message: response.data.status,
            status: 'error',
            showing: true
          })
          store.dispatch('hideIndicator')
        }
      })
      .catch((error) => {
        store.dispatch('showAlert', {
          title: 'Error: ' + error.code,
          message: error.message,
          status: 'error',
          showing: true
        })
      })
      .finally(() => {
        fetchingStatement.value = false
      })
  }

  return {
    getStatement,
    fetchingStatement,
    spinner,
  }
}
